import React, { useEffect, useRef, useState } from "react";
import { cn } from "@/lib/utils";

interface Item {
    name: string;
    img: string;
    link: string;
}

interface InfiniteMovingResourceProps {
    items: Item[];
    className?: string;
    speed?: "fast" | "normal" | "slow";
}

export const InfiniteMovingResource: React.FC<InfiniteMovingResourceProps> = ({
                                                                                  items,
                                                                                  className,
                                                                                  speed = "normal",
                                                                              }) => {
    const scrollerRef = useRef<HTMLUListElement>(null);
    const isDragging = useRef(false);
    const startPos = useRef(0);
    const currentTranslate = useRef(0);
    const velocity = useRef(0);
    const animationFrameId = useRef<number | null>(null);
    const [animationRunning, setAnimationRunning] = useState(true);
    const isFirstTouch = useRef(true); // Ref to track the first touch

    useEffect(() => {
        duplicateItems();
        if (animationRunning) {
            startAutoScroll();
        }
        return () => {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
        };
    }, [animationRunning]);

    useEffect(() => {
        // Listen to touch move events on the scroller element
        const handleTouchMove = (e: TouchEvent) => {
            if (isDragging.current) {
                e.preventDefault(); // Prevent vertical scrolling while dragging horizontally
            }
        };

        const scroller = scrollerRef.current;
        if (scroller) {
            scroller.addEventListener('touchmove', handleTouchMove, { passive: false });
        }

        return () => {
            if (scroller) {
                scroller.removeEventListener('touchmove', handleTouchMove);
            }
        };
    }, []);

    const duplicateItems = () => {
        if (scrollerRef.current) {
            const scrollerContent = Array.from(scrollerRef.current.children);
            scrollerContent.forEach((item) => {
                const duplicatedItem = item.cloneNode(true);
                scrollerRef.current?.appendChild(duplicatedItem);
            });
        }
    };

    const getSpeedPixelsPerSecond = () => {
        switch (speed) {
            case "fast":
                return 100; // 100 pixels per second
            case "slow":
                return 25; // 25 pixels per second
            default:
                return 50; // 50 pixels per second for 'normal'
        }
    };

    const startAutoScroll = () => {
        const scroller = scrollerRef.current;
        if (!scroller) return;

        let lastTime = performance.now();
        const speedPixelsPerSecond = getSpeedPixelsPerSecond();

        const scrollStep = (currentTime: number) => {
            if (!animationRunning || isDragging.current) return;

            const deltaTime = currentTime - lastTime;
            const distance = (speedPixelsPerSecond * deltaTime) / 1000;

            currentTranslate.current -= distance;
            scroller.style.transform = `translateX(${currentTranslate.current}px)`;

            lastTime = currentTime;
            requestAnimationFrame(scrollStep);
        };
        requestAnimationFrame(scrollStep);
    };

    const handleDragStart = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        isDragging.current = true;
        if (isFirstTouch.current) {
            isFirstTouch.current = false; // Marking the first touch
            setAnimationRunning(false); // Stop the animation on first touch
        }
        startPos.current = e.type === 'touchstart' ? (e as React.TouchEvent<HTMLDivElement>).touches[0].pageX : (e as React.MouseEvent<HTMLDivElement>).pageX;
        e.preventDefault();
    };

    const handleDragging = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        if (!isDragging.current) return;
        const currentPosition = e.type === 'touchmove' ? (e as React.TouchEvent<HTMLDivElement>).touches[0].pageX : (e as React.MouseEvent<HTMLDivElement>).pageX;
        const diff = currentPosition - startPos.current;
        velocity.current = diff;
        let newTranslate = currentTranslate.current + diff;

        if (newTranslate > 0) newTranslate = 0;

        currentTranslate.current = newTranslate;
        if (scrollerRef.current) {
            scrollerRef.current.style.transform = `translateX(${newTranslate}px)`;
        }
        startPos.current = currentPosition;
    };

    const handleDragEnd = () => {
        isDragging.current = false;
        const decelerate = () => {
            const decelerationRate = 0.95; // Deceleration rate
            velocity.current *= decelerationRate;
            const minVelocityThreshold = 0.5; // Minimum velocity threshold to stop inertia

            if (Math.abs(velocity.current) < minVelocityThreshold) {
                if (animationFrameId.current) {
                    cancelAnimationFrame(animationFrameId.current);
                    animationFrameId.current = null;
                    setAnimationRunning(true);
                }
                return;
            }

            currentTranslate.current += velocity.current;
            if (scrollerRef.current) {
                scrollerRef.current.style.transform = `translateX(${currentTranslate.current}px)`;
            }

            animationFrameId.current = requestAnimationFrame(decelerate);
        };

        animationFrameId.current = requestAnimationFrame(decelerate);
    };

    return (
        <div
            className={cn("scroller relative z-20 w-full overflow-x-auto", className)}
            onMouseDown={handleDragStart}
            onMouseMove={handleDragging}
            onMouseUp={handleDragEnd}
            onMouseLeave={handleDragEnd}
            onTouchStart={handleDragStart}
            onTouchMove={handleDragging}
            onTouchEnd={handleDragEnd}
        >
            <ul
                ref={scrollerRef}
                className="flex min-w-full shrink-0 gap-4 py-4 w-max flex-nowrap"
                style={{touchAction: "pan-x"}}
            >
                {items.map((item, index) => (
                    <li key={`${item.name}-${index}`} className="grid grid-rows-1 place-items-center gap-10">
                        <div className="transition-transform hover:scale-105 duration-500 z-10">
                            <a href={item.link} target="_blank" rel="noopener noreferrer"
                               className="relative flex-shrink-0 w-full space-y-2"
                               style={{touchAction: "none"}}
                            >
                                <p className="text-white text-4xl">{item.name}</p>
                                <img
                                    className="w-full h-auto object-cover rounded-md lg:w-[650px] md:w-[450px] xs:w-[350px]"
                                    src={item.img} alt={item.name}/>
                            </a>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
