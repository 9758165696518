import IconCloud from "@/components/ui/icon-cloud.tsx";

export const CloudiIcon = () => {

    const slugs = [
        "typescript",
        "javascript",
        "java",
        "react",
        "html5",
        "css3",
        "nodedotjs",
        "nextdotjs",
        "prisma",
        "postgresql",
        "nginx",
        "vercel",
        "jest",
        "docker",
        "git",
        "jira",
        "github",
        "gitlab",
        "visualstudiocode",
        "figma",
        "tailwindcss",
        "spring",
        "ionic",
        "angular"
    ];

    return (
        <div
            className="w-full rounded-lg overflow-hidden bg-background xs:px-3 sm:px-7 md:px-11 lg:px-20">
            <IconCloud iconSlugs={slugs}/>
        </div>);
};
