type CustomTitleProps = {
    title: string;
};

const BigTitle: React.FC<CustomTitleProps> = ({ title }) => {
    return (
        <div className="w-full flex flex-row items-center pl-6 space-x-3 pt-16 pb-16">
            <span className="h-1 w-20" style={{ backgroundColor: 'rgb(83, 83, 83)' }}></span>
            <p className="font-normal text-[43px]" style={{ color: 'rgb(83, 83, 83)' }}>{title}</p>
        </div>
    );
};

export default BigTitle;
