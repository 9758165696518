import WordRotate from "@/components/ui/wordRotate.tsx";

export const MovingText = () => {
    return (
        <WordRotate
            className="w-full xs:text-4xl sm:text-6xl md:text-5xl lg:text-6xl font-bold text-white z-10"
            words={["Java", "SpringBoot", "React", "Angular", "Responsive", "Development", "Design","Database"]}
        />
    );
};
