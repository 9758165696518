import { motion } from 'framer-motion';
import Spline from "@splinetool/react-spline";
import { Informations } from "@/componentDesign/Information.tsx";
import BigTilte from "@/componentDesign/BigTilte.tsx";
import Education from "@/componentDesign/Education.tsx";
import { FloatingNavbar } from "@/componentDesign/FloatingNavbar.tsx";
import Experience from "@/componentDesign/Experience.tsx";
import { MovingPic } from "@/componentDesign/MovingPic.tsx";
import { MovingResource } from "@/componentDesign/MovingResource.tsx";
import {CloudiIcon} from "@/componentDesign/CloudiIcon.tsx";
import {MovingText} from "@/componentDesign/MovingText.tsx";
import {SideProject} from "@/componentDesign/SideProject.tsx";


export const MainPage = () => {
    return (
        <div className={"w-full h-full grid place-items-center overflow-hidden"}>
            <FloatingNavbar/>
            <motion.div className={"flex flex-col justify-center items-center"}>
                <div id={"top"} className={"hidden md:block lg:block w-full section"}>
                    <Spline scene="https://prod.spline.design/ONqfNybLStMiINuQ/scene.splinecode"/>
                </div>
                <div id={"top"} className="block sm:block md:hidden lg:hidden section">
                    <Spline scene="https://prod.spline.design/GFYNXaaM6cXcqZrK/scene.splinecode"/>
                </div>
                <Informations/>
                <div
                    className={"grid sm:grid-rows-1 md:grid-cols-2 lg:grid-cols-2 items-center lg:gap-x-3 xs:gap-y-7 w-10/12 mt-20 px-11 "}>
                    <CloudiIcon/>
                    <MovingText/>
                </div>
                <div id={"projects"} className={"w-full section"}>
                    <BigTilte title={"Projects"}/>
                </div>
                <SideProject/>
                <div id={"experience"} className={"w-full section"}>
                    <BigTilte title={"Experience"}/>
                </div>
                <Experience/>
                <div id={"workspace"} className={"w-full section"}>
                    <BigTilte title={"Workspace"}/>
                </div>
                <MovingPic/>
                <div id={"education"} className={"w-full section"}>
                    <BigTilte title={"Education"}/>
                </div>
                <Education/>
                <div id={"resource"} className={"w-full section"}>
                    <BigTilte title={"Resource"}/>
                </div>
                <MovingResource/>
            </motion.div>
        </div>
    );
};
