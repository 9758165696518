import InformationItem from "@/componentDesign/EducationItem.tsx";

const Education = () => {
    return (
        <div className={"grid grid-rows-1 place-items-center gap-20 w-10/12"}>
            <InformationItem
                campus="✲ Ynov Campus Toulouse, France"
                year="2022 - 2024"
                formation="Expert In web Development"
                skills={[
                    { name: "✲ Front Development"},
                    { name: "✲ Back Development"},
                    { name: "✲ Versionning Tools"},
                    { name: "✲ CI / CD" },
                    { name: "✲ Cloud Deployment" },
                    { name: "✲ Agile" },
                    { name: "✲ React", color: "rgb(10,138,138)"},
                    { name: "✲ TypeScript", color: "rgb(0,43,217)"},
                    { name: "✲ NestJS", color: "rgb(187,0,0)"}
                ]}
                degree={"Master Degree"}
            />
            <InformationItem
                campus="✲ Université Toulouse III Jean Jaures, France"
                year="2019 - 2022"
                formation="Mathematics and Computer Science"
                skills={[
                    { name: "✲ Algorithm"},
                    { name: "✲ Algebra"},
                    { name: "✲ Analysis"},
                    { name: "✲ Probability"},
                    { name: "✲ HTML" },
                    { name: "✲ CSS" },
                    { name: "✲ VBA" ,color: "rgb(0,107,15)"},
                    { name: "✲ SQL" ,color: "rgb(196,141,0)"},
                ]}
                degree={"Licence Degree"}
            />
        </div>


    );
};

export default Education;
