const InformationItem = ({ campus, year, formation, skills, degree }: {
    campus: string;
    year: string;
    formation: string;
    skills: { name: string; color?: string }[];
    degree: string;
}) => {
    return (
        <div className="grid grid-rows-1 w-10/12">
            <div className="flex flex-row space-x-1 items-center">
                <div className="grid grid-rows-1 gap-y-2">
                    <div className="flex items-center space-x-1">
                        <p className="text-white xs:text-3xl lg:text-4xl font-extralight">{campus}</p>
                    </div>
                    <p className="xs:text-2xl lg:text-3xl font-light" style={{ color: 'rgb(83, 83, 83)' }}>{year}</p>
                    <p className="text-white xs:text-2xl lg:text-3xl font-light">{formation}</p>
                    <div className="flex flex-wrap gap-x-2.5 gap-y-2.5 items-center">
                        {skills.map((skill, index) => (
                            <div key={index} className="transition-transform hover:scale-110 duration-500 cursor-default">
                                <div className="grid place-items-center place-content-center text-xs text-gray-400 rounded-lg px-3 py-[3px] transition-colors duration-400 hover:text-white hover:border-white outline-none"
                                     style={{ backgroundColor: skill.color ? skill.color : 'rgb(52,52,52)' }}>
                                    <p className="text-gray-300 text-md font-medium" style={{ backgroundColor: skill.color ? skill.color : 'rgb(52,52,52)' }}>{skill.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className="xs:text-xl lg:text-2xl font-extralight mt-2" style={{ color: 'rgb(133,133,133)' }}>{degree}</p>
                </div>
            </div>
        </div>
    );
};

export default InformationItem;
