import InformationItem from "@/componentDesign/EducationItem.tsx";

const Experience = () => {
    return (
        <div className={"grid  grid-rows-1 place-items-center gap-20 w-10/12"}>
            <InformationItem
                campus="✲ Air France, Toulouse"
                year="2022 - 2024"
                formation="Web Developer Junior Full Stack "
                skills={[
                    { name: "✲ Front Development"},
                    { name: "✲ Back Development"},
                    { name: "✲ BitBucket"},
                    { name: "✲ Jira" },
                    { name: "✲ Confluence" },
                    { name: "✲ Kanban" },
                    { name: "✲ Java", color: "rgb(178,85,20)"},
                    { name: "✲ SpringBoot", color: "rgb(86,129,25)"},
                    { name: "✲ Mockito", color: "rgb(19,86,29)"},
                    { name: "✲ Angular", color: "rgb(133,23,23)"},
                    { name: "✲ Ionic", color: "rgb(33,53,141)"},
                    { name: "✲ TypeScript", color: "rgb(15,47,178)"},
                    { name: "✲ Tailwind", color: "rgb(16,101,89)"},
                    { name: "✲ GitHub", color: "rgb(57,34,134)"},
                ]}
                degree={"Apprenticeship"}
            />
            <InformationItem
                campus="✲ American Vintage, Toulouse"
                year="2021 - 2022"
                formation="Ready-to-wear Seller"
                skills={[
                    { name: "✲ Sale of semi-luxury clothing"},
                    { name: "✲ Inventory management"},
                    { name: "✲ Handling"},
                    { name: "✲ Maintenance"},
                    { name: "✲ Relationship Sales technique" },
                ]}
                degree={"Permanent Contract"}
            />
            <InformationItem
                campus="✲ Courir, Toulouse"
                year="2019 - 2021"
                formation="Ready-to-wear Seller"
                skills={[
                    { name: "✲ Sale of clothes and sneakers"},
                    { name: "✲ Inventory management"},
                    { name: "✲ Handling"},
                    { name: "✲ Maintenance"},
                    { name: "✲ Relationship Sales technique" },
                ]}
                degree={"Permanent Contract"}
            />
            <InformationItem
                campus="✲ Capio France, Toulouse"
                year="2019 - 2019"
                formation="IT Technician"
                skills={[
                    { name: "✲ Hardware Installation"},
                    { name: "✲ OS Installation"},
                    { name: "✲ Windows to Linux system migration"},
                    { name: "✲ Ticket Management"},
                    { name: "✲ IT Maintenance" }
                ]}
                degree={"Internship"}
            />
        </div>


    );
};

export default Experience;

