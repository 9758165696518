import {BookText, Instagram, Linkedin, Mail, MapPin, Squircle, Twitter} from "lucide-react";
export const Informations = () => {

    return (
        <div className={"w-10/12 xs:pt-6 sm:pt-6 md:pt-8 lg:pt-8 grid gap-4"}>
            <h1 className={"flex text-left text-white font-bold xs:text-5xl md:text-5xl lg:text-5xl"}>Senouci M.</h1>
            <div className="grid sm:grid-rows-1 md:grid-cols-2 lg:grid-cols-4 gap-y-2">
                <div className={"flex flex-row space-x-1 items-center"}>
                    <Squircle className={"text-gray-500"} size={20} strokeWidth={3}/>
                    <p className={"text-white xs:text-xl md:text-2xl lg:text-2xl font-normal"}>Web Developer</p>
                </div>
                <div className={"flex flex-row space-x-1 items-center"}>
                    <Squircle className={"text-gray-500"} size={20} strokeWidth={3}/>
                    <p className={"text-white xs:text-xl md:text-2xl lg:text-2xl font-normal"}>Apple Enthusiast</p>
                </div>
                <div className={"flex flex-row space-x-1 items-center"}>
                    <Squircle className={"text-gray-500"} size={20} strokeWidth={3}/>
                    <p className={"text-white xs:text-xl md:text-2xl lg:text-2xl font-normal"}>Aesthetic Setup</p>
                </div>
            </div>
            <div className={"flex flex-row items-center space-x-1"}>
                <MapPin className={"text-gray-500"} size={20} strokeWidth={1.75}/>
                <p className={"text-white xs:text-md lg:text-lg font-normal"}>Based in France</p>
            </div>
            <div className={"flex flex-wrap gap-x-2.5 gap-y-2.5 items-center"}>
                <div
                    className="flex flex-row items-center justify-center transition-transform hover:scale-110 duration-500">
                    <a href="https://read.cv/moulaysenouci" target="_blank" rel="noopener noreferrer"
                       className="text-md inline-flex animate-shimmer items-center justify-center rounded-lg border border-gray-50 space-x-2 px-4 py-[3px] bg-[linear-gradient(110deg,#999999,45%,#cccccc,55%,#999999)] bg-[length:200%_100%] font-medium text-white transition-colors focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-50">
                        <BookText className={"bg-transparent"} width="14" height="20" strokeWidth="1.75"/>
                        <span className={"bg-transparent"}>Hire</span>
                    </a>
                </div>
                <a href={"mailto:senouci.moulay@gmail.com"}
                   className="transition-transform hover:scale-110 duration-500">
                    <div
                        className={"flex flex-row items-center justify-center text-md text-gray-400 border-[1px] rounded-lg border-gray-400 space-x-2 px-5 py-[4px] transition-colors duration-400 hover:text-white hover:border-white"}>
                        <Mail size={14} strokeWidth={1.75}/>
                        <a>Mail</a>
                    </div>
                </a>
                <a href={"https://www.linkedin.com/in/moulay-senouci-b90810235/"} target="_blank"
                   rel="noopener noreferrer" className="transition-transform hover:scale-110 duration-500">
                    <div
                        className="text-md text-gray-400 border-[1px] rounded-lg border-gray-400 px-5 py-[4.5px] transition-colors duration-400 hover:text-white hover:border-white">
                        <Linkedin size={22} strokeWidth={1.75}/>
                    </div>
                </a>
                <a href={"https://www.instagram.com/syynopsiis/"} target="_blank" rel="noopener noreferrer"
                   className="transition-transform hover:scale-110 duration-500">
                    <div
                        className="text-sm text-gray-400 border-[1px] rounded-lg border-gray-400 px-5 py-[4.5px] transition-colors duration-400 hover:text-white hover:border-white">
                        <Instagram size={22} strokeWidth={1.75}/>
                    </div>
                </a>
                <a href={"https://twitter.com/synopsisou"} target="_blank" rel="noopener noreferrer"
                   className="transition-transform hover:scale-110 duration-500">
                    <div
                        className="text-sm text-gray-400 border-[1px] rounded-lg border-gray-400 px-5 py-[4.5px] transition-colors duration-400 hover:text-white hover:border-white">
                        <Twitter size={22} strokeWidth={1.75}/>
                    </div>
                </a>
            </div>
        </div>
    );


};

