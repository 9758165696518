import { BorderBeam } from "@/components/ui/border-beam.tsx";
import PicPhone from "@/assets/KALEIPHONE.gif"
import Pic1 from "@/assets/KALEIDOSCOPE.gif"

export function BorderBeamDemo() {
    return (
        <div className="xs:w-11/12 sm:w-11/12 md:w-11/12 lg:w-full rounded-xl grid place-content-center h-full">
            <img
                src={Pic1}
                alt="Hero Image"
                className="xs:block sm:block md:hidden lg:block w-[700px] rounded-[inherit] border object-contain shadow-lg"
            />
            <iframe
                className="xs:hidden sm:hidden md:hidden lg:hidden lg:w-[560px] h-[400px] rounded-[inherit] border shadow-lg"
                src="https://kaleidoscope-l4zz.vercel.app/?muted=true"
                name="iframe_a"
                title="Iframe Example"
                allow="autoplay; mute"/>
            <img
                src={PicPhone}
                alt="Hero Image"
                className="xs:hidden sm:hidden md:block lg:hidden w-[700px] rounded-[inherit] border object-contain shadow-lg"
            />
            <BorderBeam size={250} duration={12} delay={9}/>
        </div>
    );
}
