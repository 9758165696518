import { useState, useEffect } from 'react';
export const FloatingNavbar = () => {
    const [activeSection, setActiveSection] = useState('top');

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.section');
            let currentSection = '';
            sections.forEach((section) => {
                const sectionTop = section.getBoundingClientRect().top;
                if (sectionTop <= window.innerHeight * 0.5) {
                    currentSection = section.id;
                }
            });
            setActiveSection(currentSection);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="flex items-center justify-center w-10/12">
            <div
                className="z-30 backdrop-blur-md fixed bottom-8 grid xs:grid-cols-5 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-10 place-items-center gap-1 xs:4/12 lg:w-5/12 min-w-80 max-w-[370px] border-[1px] border-gray-600 rounded-lg py-3 px-3 transition-transform md:hover:scale-110 lg:hover:scale-110 duration-300"
                style={{backgroundColor: 'rgba(0, 0, 0, 0.47)'}}
            >

                <a
                    href="#projects"
                    className={`text-xs ${activeSection === 'projects' ? 'text-white' : 'text-gray-400'} bg-transparent transition-transform hover:scale-110 duration-300 lg:col-span-2 text-center`}
                >
                    Projects
                </a>
                <a
                    href="#experience"
                    className={`text-xs ${activeSection === 'experience' ? 'text-white' : 'text-gray-400'} bg-transparent transition-transform hover:scale-110 duration-300 lg:col-span-2 mr-2`}
                >
                    Experience
                </a>
                <a
                    href="#workspace"
                    className={`text-xs ${activeSection === 'workspace' ? 'text-white' : 'text-gray-400'} bg-transparent transition-transform hover:scale-110 duration-300 lg:col-span-2`}
                >
                    WorkSpace
                </a>
                <a
                    href="#education"
                    className={`text-xs ${activeSection === 'education' ? 'text-white' : 'text-gray-400'} bg-transparent transition-transform hover:scale-110 duration-300 lg:col-span-2 ml-2`}
                >
                    Education
                </a>
                <a
                    href="#resource"
                    className={`text-xs ${activeSection === 'resource' ? 'text-white' : 'text-gray-400'} bg-transparent transition-transform hover:scale-110 duration-300 lg:col-span-2`}
                >
                    Resource
                </a>
            </div>
        </div>
    );
};
