import { BorderBeamDemo } from "@/componentDesign/BorderBeam.tsx";
import {Link} from "lucide-react";
export const SideProject = () => {
    return (
        <div className={"w-full md:w-10/12 mx-auto grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-6"}>
            <div className={"grid place-content-center place-items-center"}>
                <BorderBeamDemo />
            </div>
            <div
                className={"grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-rows-[auto,auto,1fr,auto] xs:px-7 sm:px-7 md:px-7 lg:px-0"}>
                <div className={"flex items-center space-x-2"}>
                    <a href={"https://kaleidoscope-l4zz.vercel.app"} target="_blank" rel="noopener noreferrer"
                       className={"text-white text-4xl underline underline-offset-7 mb-1"}>Kaleidoscope</a>
                    <Link className={"text-white mt-1"} size={22}/>
                </div>
                <p className="font-light mt-0.5" style={{color: 'rgb(83, 83, 83)'}}>
                    Collaborator: François Bonnin | Raphael Marie | Mohcine Elbah | Justine Portalé | Amable Alex
                </p>
                <p className={"text-white font-light text-justify text-md mt-6"}>
                    Come and discover the world of cinema colors. Kaleidoscope offers you a visual and auditory
                    experience centered around colors. Grab your best audio device and discover or rediscover your
                    favorite works through their most beautiful frames.
                    Search and filter by name for a precise and concise experience. Or let yourself be carried away by
                    sorting according to color. Independent project carried out in a school setting which we hope will
                    have a pleasant interface for you. The objective is to understand the importance of the interface
                    for the user experience.
                    A website developed in NextJS.
                </p>
                <p className="font-light text-xs xs:mt-6" style={{color: 'rgb(83, 83, 83)'}}>
                    The works presented do not belong to us and remain the exclusive property of their respective
                    authors.
                </p>
            </div>
        </div>
    );
};
