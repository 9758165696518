import {InfiniteMovingPic} from "@/components/ui/infinitePicWorkspace/infiniteMovingPic.tsx";
import W1 from "@/assets/workspace/1.jpg";
import W2 from "@/assets/workspace/2.jpg";
import W3 from "@/assets/workspace/3.jpg";
import W4 from "@/assets/workspace/4.jpg";
import W5 from "@/assets/workspace/5.jpg";
import W6 from "@/assets/workspace/6.jpg";
import W7 from "@/assets/workspace/7.jpg";
import W8 from "@/assets/workspace/8.jpg";
import W9 from "@/assets/workspace/9.jpg";
import W10 from "@/assets/workspace/10.jpg";
import W11 from "@/assets/workspace/11.jpg";
export const MovingPic = () => {


    const workSpacePics: any[] =[
        {
            name: "W1",
            img: W1
        },
        {
            name: "W2",
            img: W2
        }
        ,{
            name: "W3",
            img: W3
        },
        {
            name: "W4",
            img: W4
        },
        {
            name: "W5",
            img: W5
        },
        {
            name: "W6",
            img: W6
        },
        {
            name: "W7",
            img: W7
        },
        {
            name: "W8",
            img: W8
        },
        {
            name: "W9",
            img: W9
        },
        {
            name: "W10",
            img: W10
        },
        {
            name: "W11",
            img: W11
        },
    ]

    return (
        <div className={"grid place-content-center w-full gap-y-9"}>
            <InfiniteMovingPic items={workSpacePics}/>
        </div>
    );
};
