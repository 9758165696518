"use client";

import { cn } from "@/lib/utils";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { useEffect, useState } from "react";

interface WordRotateProps {
    words: string[];
    duration?: number;
    framerProps?: HTMLMotionProps<"h1">;
    className?: string;
}

export default function WordRotate({
                                       words,
                                       duration = 1700,
                                       framerProps = {
                                           initial: { opacity: 0, y: -50 },
                                           animate: { opacity: 1, y: 0 },
                                           exit: { opacity: 0, y: 50 },
                                           transition: { duration: 0.10, ease: "easeOut" },
                                       },
                                       className,
                                   }: WordRotateProps) {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, duration);

        // Clean up interval on unmount
        return () => clearInterval(interval);
    }, [words, duration]);

    return (
        <div className="w-full overflow-hidden py-2 grid justify-center">
            <AnimatePresence mode="wait">
                <motion.h1
                    key={words[index]}
                    className={cn(className)}
                    {...framerProps}
                >
                    {words[index]}
                </motion.h1>
            </AnimatePresence>
        </div>
    );
}
