import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {MainPage} from "@/componentDesign/MainPage.tsx";
function App() {

    return (
    <BrowserRouter>
        <div className="grid place-items-center pb-40">
            <Routes>
                <Route path="/" element={<MainPage/>}/>
            </Routes>
        </div>
    </BrowserRouter>
    );
}

export default App;
