import Oliur from "@/assets/resources/oliur.png";
import Svgl from "@/assets/resources/svgl.png";
import Uiverse from "@/assets/resources/uiverse.png";
import Lucide from "@/assets/resources/lucide.png";
import Shad from "@/assets/resources/shad.png";
import UIlabs from "@/assets/resources/uilabs.png";
import Vercel from "@/assets/resources/vercel.png";
import {InfiniteMovingResource} from "@/components/ui/infinitePicResource/infiniteMovingResource.tsx";
export const MovingResource = () => {


    const workSpacePics: any[] =[
        {
            name: "Oliur Wallpaper",
            img: Oliur,
            link: "https://oliur.gumroad.com/l/gradient-blur"

        },
        {
            name: "SVGL",
            img: Svgl,
            link: "https://svgl.vercel.app/"
        }
        ,{
            name: "Uiverse",
            img: Uiverse,
            link: "https://uiverse.io"
        },
        {
            name: "Lucide",
            img: Lucide,
            link: "https://lucide.dev/"
        },
        {
            name: "Shadcn",
            img: Shad,
            link: "https://ui.shadcn.com/"
        },
        {
            name: "UIlabs",
            img: UIlabs,
            link: "https://www.uilabs.dev/"
        },
        {
            name: "Vercel",
            img: Vercel,
            link: "https://vercel.com/"
        },
    ]

    return (
        <div className={"grid place-content-center w-full gap-y-9"}>
            <InfiniteMovingResource items={workSpacePics}/>
        </div>
    );
};
